<h-modal
    (closeModal)="close()"
    [topOffset]="(topOffset$ | async) ?? undefined"
    ariaLabelledby="add-to-cart-title"
    hModalPositioning
    i18n-ariaLabelledby
    id="notif-add-to-cart">
    <div h-modal-header>
        <button
            (click)="close()"
            aria-label="Close"
            cdkFocusInitial
            class="notification-close"
            i18n-aria-label="@@hermes.accessibility.close_tray"
            type="button">
            <h-svg-icon [name]="'cross-rounded'"></h-svg-icon>
        </button>

        <ng-template #successAddToCart>
            <div class="cart-valid-icon">
                <h-svg-icon [name]="'cart'"></h-svg-icon>
            </div>
            <h2 class="title paragraph-medium" i18n="@@hermes_notification.cart.add" id="add-to-cart-title">
                This item has been added to the cart
            </h2>
        </ng-template>

        <ng-template #failedAddToCart>
            <div class="cart-valid-icon">
                <h-svg-icon [name]="'info'"></h-svg-icon>
            </div>
            <h2 class="title paragraph-small" id="add-to-cart-title">
                {{ this.modalData.errorMessage }}
            </h2>
        </ng-template>

        <ng-container [ngTemplateOutlet]="modalData.isFailedAddToCart ? failedAddToCart : successAddToCart">
        </ng-container>
    </div>
    <div h-modal-body>
        @if (modalData.standardDelivery) {
        <div>
            <div class="separator"></div>
            <span class="secondary-text" i18n="@@hermes_notification.cart.standard-delivery">
                Request an exchange or return online
            </span>
            <div class="separator"></div>
        </div>
        }
    </div>
    @if (!modalData.isFailedAddToCart) {
    <div h-modal-footer>
        <h-call-to-action
            (click)="goToCart()"
            [dataTestId]="'View cart'"
            [name]="'view-cart'"
            [size]="'medium'"
            [trayTargetId]="'tray-cart'"
            i18n="@@hermes-global-translations.view-cart">
            View cart
        </h-call-to-action>
    </div>
    }
</h-modal>
<div (click)="close()" class="modal-background" id="modal-add-to-cart-background"></div>
