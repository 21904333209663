import { Response, Request } from "express";

export const FAKE_DOMAIN = "http://fake";

/**
 * Redirect response with cache-control header
 * @param request Request
 * @param response Response
 * @param newPath string
 * @param statusCode 301 | 302
 * @param forceCleanQueryParameters boolean remove all query parameters from request url
 * @param cleanSearchParamter boolean remove only the search ("?s") query param (used for SEO purpose)
 */
export function redirectResponse({
    request,
    response,
    newPath,
    statusCode = 301,
    forceCleanQueryParameters = false,
}: {
    request: Request;
    response: Response;
    newPath: string;
    statusCode?: 301 | 302;
    forceCleanQueryParameters?: boolean;
}): void {
    if (forceCleanQueryParameters) {
        request.url = request.url.split("?")[0];
    }

    const requestUrl = new URL(request.url, FAKE_DOMAIN);
    const destinationUrl = new URL(newPath, FAKE_DOMAIN);

    const newSearchQueryParameter = new URLSearchParams();

    // Usage of URLSearchParams.set() is intended, we want to keep only one query param
    // order of the addition is also intentional, this is the priority we set
    // reRequest URL < Destination URL

    // Add query param from request URL (compatibility with new Nginx)
    createNewSearchQueryParameters(
        newSearchQueryParameter,
        requestUrl.searchParams,
    );

    // Add query param from redirection URL target
    createNewSearchQueryParameters(
        newSearchQueryParameter,
        destinationUrl.searchParams,
    );

    // Copy back new query to destination URL
    createNewSearchQueryParameters(
        destinationUrl.searchParams,
        newSearchQueryParameter,
    );

    response.header(
        "cache-control",
        statusCode === 301
            ? "public, max-age=300"
            : "public, max-age=10, no-cache, no-store",
    );
    addTrailingSlash(destinationUrl);
    response.redirect(
        statusCode,
        `${destinationUrl.pathname}${destinationUrl.search}`,
    );
    response.end();
}

function createNewSearchQueryParameters(
    newSearchQueryParameter: URLSearchParams,
    oldSearchQueryParameter: URLSearchParams,
): void {
    for (const [key, value] of oldSearchQueryParameter.entries()) {
        newSearchQueryParameter.set(key, value);
    }
}

/**
 * Method used to create redirection url with trailing slash when missing.
 *
 * @param url url to rebuild with trailing slash
 */
function addTrailingSlash(url: URL): void {
    if (url.pathname.slice(-1) !== "/") {
        url.pathname = `${url.pathname}/`;
    }
}
