import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";

import { Edito } from "@hermes/api-model-editorial";
import { MenuEntryType } from "@hermes/api-model-shell";
import { getEdito } from "@hermes/fragments/menu-shared";
import { MenuEntryItem } from "@hermes/states/menu";

@Component({
    selector: "h-menu-sub-category",
    templateUrl: "./menu-sub-category.component.html",
    styleUrls: ["./menu-sub-category.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSubCategoryComponent implements OnInit {
    @Input()
    public menuEntry!: MenuEntryItem;

    @Output()
    public closeSubMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public isFirstCategory: boolean = false;

    @Input()
    public isLastCategory: boolean = false;

    public menuEntryType = MenuEntryType;

    public edito?: Edito;

    public ngOnInit(): void {
        this.edito = getEdito(this.menuEntry);
    }

    public onEscapeKey(event: Event): void {
        event.stopPropagation();
        this.closeSubMenu.emit();
    }

    public onKeyDown(
        event: KeyboardEvent,
        index: number,
        subIndex?: number,
    ): void {
        if (event.key === "Tab") {
            const isLastCategory =
                index === this.menuEntry.menuEntryList.length - 1;
            const menuEntry = this.menuEntry.menuEntryList[index];

            const hasSubCategories =
                "menuEntryList" in menuEntry &&
                Array.isArray(menuEntry.menuEntryList) &&
                menuEntry.menuEntryList.length > 0;

            const isLastSubCategory =
                hasSubCategories &&
                subIndex !== undefined &&
                subIndex === menuEntry.menuEntryList.length - 1;
            if (
                isLastCategory &&
                (!hasSubCategories || isLastSubCategory) &&
                this.isLastCategory
            ) {
                this.closeSubMenu.emit(true);
            }
        }
    }
}
