<a
    #anchor
    [id]="anchorId"
    [href]="menuEntry.path"
    [class.keyboard-navigation]="layoutFacade.keyboardNavigation$ | async"
    [class.active]="currentUrl === menuEntryPath && primaryEntry"
    [attr.aria-current]="currentUrl === menuEntryPath && primaryEntry ? 'page' : null"
    [class]="menuLinkLevel"
    (click)="click($event)"
    (keydown.enter)="click($event)"
    (keydown.space)="click($event)"
    [innerHTML]="menuEntry.name"></a>
