import { BreakpointObserver } from "@angular/cdk/layout";
import { DOCUMENT } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    WritableSignal,
    effect,
    signal,
} from "@angular/core";
import isEqual from "lodash-es/isEqual";
import { Observable, Subscription, combineLatest } from "rxjs";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import {
    Context,
    StorageManager,
    StorageService,
    WINDOW,
} from "@hermes/app-core";
import {
    ENABLE_HORIZONTAL_MENU_HEADER,
    FeatureFlagFacade,
} from "@hermes/states/flipper";
import { MenuFacade } from "@hermes/states/menu";
import { TrayStackService } from "@hermes/states/tray";
import {
    ObserverFactory,
    WindowWithResizeObserver,
} from "@hermes/utils-generic/helpers";
import { BreakpointService } from "@hermes/utils-generic/services/user-interface";

import {
    HEADER_DESKTOP_HEIGHT,
    HEADER_DESKTOP_HEIGHT_WITH_MENU,
    HEADER_MOBILE_HEIGHT,
} from "../../constants/header.constant";
import {
    calculateScrollLimit,
    getHeaderStateFromScroll,
} from "../../helpers/header.helper";
import { HeaderInnerState, PositionType } from "../../model/header.model";
import { MenuParentCategoryComponent } from "../menu-bar/parent-category/menu-parent-category.component";

@Component({
    selector: "h-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
    /**
     * Reference to the wrapper of main header content (menu, search, account, basket).
     */
    @ViewChild("headerMainContainer", { static: true })
    public headerMainContainerEl!: ElementRef<HTMLElement>;

    @ViewChild("bannerContainer", { static: true })
    public bannerContainerEl!: ElementRef<HTMLElement>;

    @ViewChild("headerMain", { static: true })
    public headerMainEl!: ElementRef<HTMLElement>;

    @ViewChild("headerAndBannerContainer", { static: true })
    public headerAndBannerContainerEl!: ElementRef<HTMLElement>;

    @ViewChild("headerBackgroundFixed", { static: true })
    public headerBackgroundFixedEl!: ElementRef<HTMLElement>;

    @ViewChild("headerLinksContainer", { static: true })
    public headerLinksContainerEl!: ElementRef<HTMLElement>;

    @ViewChild("menuBar", { static: true })
    public menuBarEL!: ElementRef<HTMLElement>;

    public readonly HEADER_MAIN_DESKTOP_HEIGHT = HEADER_DESKTOP_HEIGHT;
    public readonly HEADER_MAIN_MOBILE_HEIGHT = HEADER_MOBILE_HEIGHT;
    public readonly HEADER_DESKTOP_HEIGHT_WITH_MENU =
        HEADER_DESKTOP_HEIGHT_WITH_MENU;

    /**
     * State of the header
     */
    public headerState: WritableSignal<HeaderInnerState> = signal({
        headerPosition: PositionType.Relative,
        isOnlyMenuBarDisplayed: false,
    });

    public isMenuHeaderFFActivated: WritableSignal<boolean> = signal(false);

    public isHeaderTransparent$: Observable<boolean>;

    public isInServerMode: boolean;

    public isUnderMenuBreakpoint$: Observable<boolean>;

    /**
     * Inputs for account creation banner
     */
    public isAccountCreated: boolean = false;
    public isAccountCreatedByWechat: boolean = false;
    public isSubMenuActive = signal(false);
    public subscription: Subscription = new Subscription();
    private sessionStorage: StorageManager | undefined;
    private lastScrollY: number;
    /* Provide resizeObserver */
    private observerFactory: ObserverFactory;

    constructor(
        @Inject(WINDOW) private window: WindowWithResizeObserver,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private context: Context,
        private zone: NgZone,
        private layoutFacade: LayoutFacade,
        private trayStack: TrayStackService,
        public menuFacade: MenuFacade,
        private featureFlagFacade: FeatureFlagFacade,
        private storageService: StorageService,
        private breakpointService: BreakpointService,
        private breakpointObserver: BreakpointObserver,
    ) {
        this.sessionStorage = storageService.getSessionStorageInstance();
        this.lastScrollY = this.window.scrollY;
        this.isHeaderTransparent$ = this.layoutFacade.headerTransparent$;
        this.isInServerMode = this.context.isInServerMode();

        this.observerFactory = new ObserverFactory(window);

        this.isUnderMenuBreakpoint$ = this.breakpointObserver
            .observe("(min-width: 1024px)")
            .pipe(map((value) => !value.matches));

        effect(
            () => {
                const activeMenu = MenuParentCategoryComponent.activeMenu();
                this.isSubMenuActive?.set(
                    !!activeMenu && activeMenu.isActive(),
                );
            },
            { allowSignalWrites: true },
        );
    }
    public ngOnInit(): void {
        if (this.context.isInBrowserMode()) {
            this.getAccountCreationBanner();
        }

        this.menuFacade.fetchMenu();

        let scrollBarWidth = 0;

        setTimeout(() => {
            scrollBarWidth =
                this.window.innerWidth -
                this.document.documentElement.clientWidth;
        });

        this.subscription.add(
            this.featureFlagFacade
                .isActivated(ENABLE_HORIZONTAL_MENU_HEADER)
                .subscribe((isMenuHeaderActivated) => {
                    this.isMenuHeaderFFActivated.set(isMenuHeaderActivated);
                }),
        );

        this.subscription.add(
            this.trayStack.trays$
                .pipe(
                    filter(
                        () =>
                            this.headerState().headerPosition ===
                                PositionType.Fixed &&
                            this.breakpointService.mediumBreakpointMatcher(),
                    ),
                    map((trays) => trays.some((tray) => tray.isOpen)),
                    distinctUntilChanged(),
                )
                .subscribe((isOpen) => {
                    // When a tray is opened, additional right padding is added to make up for the scrollbar disappearing,
                    // to avoid header content from shifting.
                    isOpen
                        ? this.renderer.setStyle(
                              this.headerMainContainerEl.nativeElement,
                              "right",
                              `${scrollBarWidth}px`,
                          )
                        : this.renderer.removeStyle(
                              this.headerMainContainerEl.nativeElement,
                              "right",
                          );
                }),
        );
    }

    public ngAfterViewInit(): void {
        const fullHeight =
            this.headerMainContainerEl.nativeElement.offsetHeight;
        this.layoutFacade.setOriginalFullHeaderHeight(fullHeight);
        if (this.context.isInBrowserMode()) {
            this.zone.runOutsideAngular(() => {
                this.window.addEventListener("scroll", this.scrollHandler);
            });

            this.subscription.add(
                combineLatest([
                    this.observerFactory
                        .observeResize(
                            this.headerAndBannerContainerEl.nativeElement,
                        )
                        .pipe(startWith(0), distinctUntilChanged()),
                    this.observerFactory
                        .observeResize(this.headerMainContainerEl.nativeElement)
                        .pipe(startWith(0), distinctUntilChanged()),
                ]).subscribe(() => {
                    this.updateStoreHeaderHeight();
                }),
            );
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        if (this.context.isInBrowserMode()) {
            this.window.removeEventListener("scroll", this.scrollHandler);
        }
    }

    public closeAccountCreationBanner(): void {
        this.isAccountCreated = false;
        this.isAccountCreatedByWechat = false;
    }

    /* scroll handler, in an arrow function to be able to cancel it.
     * And to treat it outside angular.
     */
    private scrollHandler = (): void => {
        const currentScrollY = this.window.scrollY;
        const isScrollingDown = currentScrollY > this.lastScrollY;
        const scrollLimit = calculateScrollLimit(
            this.bannerContainerEl.nativeElement.offsetHeight,
            this.headerMainEl.nativeElement.offsetHeight,
            isScrollingDown,
            this.isMenuHeaderFFActivated() &&
                this.breakpointObserver.isMatched("(min-width: 1024px)"),
        );

        const newHeaderState = getHeaderStateFromScroll(
            scrollLimit,
            currentScrollY,
            isScrollingDown,
        );
        if (!isEqual(this.headerState(), newHeaderState)) {
            this.zone.run(() => {
                this.headerState.set(newHeaderState);
                this.updateStoreHeaderHeight();
            });
        }
        this.lastScrollY = currentScrollY;
    };

    private getAccountCreationBanner() {
        this.isAccountCreated = this.checkAccountCreationBannerVisibility();
        if (this.isAccountCreated) {
            this.isAccountCreatedByWechat = !!this.sessionStorage?.getItem(
                "accountCreatedByWechat",
            );
            this.sessionStorage?.deleteItem("accountCreated");
            this.sessionStorage?.deleteItem("accountCreatedByWechat");
        }
    }

    private checkAccountCreationBannerVisibility(): boolean {
        return !!this.sessionStorage?.getItem("accountCreated");
    }

    private updateStoreHeaderHeight(): void {
        const topHeaderBar =
            this.headerState().headerPosition === PositionType.Fixed
                ? this.headerMainContainerEl.nativeElement.offsetHeight
                : 0;
        this.renderer.setStyle(
            document.documentElement,
            "--header-menu-top",
            `${topHeaderBar}px`,
            2,
        );
        this.layoutFacade.updateHeaderProperties({
            headerHeightWithLinks:
                this.headerMainContainerEl.nativeElement.offsetHeight +
                this.headerLinksContainerEl.nativeElement.offsetHeight,
            headerMainHeight:
                this.headerMainContainerEl.nativeElement.offsetHeight,
            headerAndBannerHeight:
                this.headerAndBannerContainerEl.nativeElement.offsetHeight,
            menubarHeight: this.menuBarEL.nativeElement.offsetHeight,
            isFixed: this.headerState().headerPosition === PositionType.Fixed,
        });
    }
}
