export enum PageProductImageMediaqueries {
    Mobile = "(max-width: 414px)",
    Tablet = "(max-width: 768px)",
    Desktop = "(max-width: 1281px)",
    Large = "(max-width: 1921px)",
    IsNotMobile = "(min-width: 415px)",
}

export const PRODUCT_IN_GRID_MOBILE = 400;
export const PRODUCT_IN_GRID_DESKTOP = 700;
export const HERO_PRODUCT_IN_GRID_DESKTOP = 800;
export const PRODUCT_PAGE_IMAGE_SIZE = 800;
export const GIFTSET_PAGE_IMAGE_SIZE = 1350;
export const DEFAULT_MAX_VISIBLE_IMAGES_ON_PRODUCT_PAGE = 5;
