/* eslint-disable @typescript-eslint/member-ordering */
import {
    ChangeDetectionStrategy,
    Component,
    QueryList,
    ViewChildren,
    WritableSignal,
    inject,
    signal,
} from "@angular/core";

import { toSignal } from "@angular/core/rxjs-interop";
import { Subject, filter, map } from "rxjs";

import { MenuEntryType } from "@hermes/api-model-shell";
import { MenuEntryItem, MenuFacade } from "@hermes/states/menu";

import { HEADER_MENU_HEIGHT } from "../../constants/header.constant";

import { MenuParentCategoryComponent } from "./parent-category/menu-parent-category.component";

@Component({
    selector: "h-menu-bar",
    templateUrl: "./menu-bar.component.html",
    styleUrls: ["./menu-bar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuBarComponent {
    @ViewChildren(MenuParentCategoryComponent)
    public parentCategories!: QueryList<MenuParentCategoryComponent>;

    // Set initial value to -1 to not focus any element on loading
    public parentCategoryFocusedIndex: WritableSignal<number> = signal(-1);

    public readonly headerMenuHeight = HEADER_MENU_HEIGHT;

    private menuFacade = inject(MenuFacade);

    public menuEntry$ = this.menuFacade.menuEntry$;
    public menuEntryType = MenuEntryType;
    public menuEntryLength = toSignal(
        this.menuEntry$.pipe(
            filter(Boolean),
            map((menuEntryItem) => menuEntryItem.menuEntryList.length),
        ),
        { initialValue: 0 },
    );

    public subMenuEntryActive: MenuEntryItem | undefined;

    public mousLeaveEventsSubject: Subject<void> = new Subject<void>();

    private isShiftKeyActive = false;

    public onMouseLeave() {
        this.mousLeaveEventsSubject.next();
    }

    public focusRight() {
        this.parentCategoryFocusedIndex.update((value) => value + 1);
        if (this.parentCategoryFocusedIndex() === this.menuEntryLength()) {
            this.parentCategoryFocusedIndex.set(0);
        }
    }

    public focusLeft() {
        this.parentCategoryFocusedIndex.update((value) => value - 1);
        if (this.parentCategoryFocusedIndex() < 0) {
            this.setFocusToLastItem();
        }
    }

    public setFocusToFistItem() {
        this.parentCategoryFocusedIndex.set(0);
    }

    public setFocusToLastItem() {
        this.parentCategoryFocusedIndex.set(this.menuEntryLength() - 1);
    }

    public closeAllMenus(): void {
        this.parentCategories.forEach((cat) => {
            if (cat.isActive()) {
                cat.closeMenu(true);
            }
        });
    }

    public onKeyDown(event: KeyboardEvent, index: number): void {
        if (event.key === "Shift") {
            this.isShiftKeyActive = true;
            return;
        }

        if (event.key === "Tab") {
            if (this.isShiftKeyActive) {
                if (index === 0 && this.focusIsOnParentCategory(index)) {
                    this.closeAllMenus();
                    this.isShiftKeyActive = false;
                }
            } else {
                if (
                    index === this.menuEntryLength() - 1 &&
                    !this.lastCategoryHasOpenSubMenu()
                ) {
                    this.closeAllMenus();
                }
            }
        }
    }

    public onKeyUp(event: KeyboardEvent): void {
        if (event.key === "Shift") {
            this.isShiftKeyActive = false;
        }
    }

    private focusIsOnParentCategory(index: number): boolean {
        const parentCategory = this.parentCategories.toArray()[index];
        if (!parentCategory) {
            return false;
        }
        const buttonElement = parentCategory.menuEntryButton?.nativeElement;
        const linkElement =
            parentCategory.menuLinkComponent?.getFocusableElement();

        return (
            document.activeElement === buttonElement ||
            document.activeElement === linkElement
        );
    }

    public lastCategoryHasOpenSubMenu(): boolean {
        return this.parentCategories?.last?.isActive();
    }
}
