export type Size =
    | "xsmall"
    | "small"
    | "medium"
    | "large"
    | "xlarge"
    | "xxlarge";

export interface MediaPicture {
    mobileImage?: string;
    desktopImage?: string;
    imageAlt?: string;
}

export interface MediaQueryOption {
    size: Size;
    mediaQuery: string;
    width: number;
}

export const DEFAULT_MEDIA_QUERIES_OPTIONS: MediaQueryOption[] = [
    {
        size: "xsmall",
        mediaQuery: "(min-width: 320px) and (max-width: 414px)",
        width: 414,
    },
    {
        size: "small",
        mediaQuery: "(min-width: 415px) and (max-width: 767px)",
        width: 767,
    },
    {
        size: "medium",
        mediaQuery: "(min-width: 768px) and (max-width: 1024px)",
        width: 1024,
    },
    {
        size: "large",
        mediaQuery: "(min-width: 1025px) and (max-width: 1280px)",
        width: 1280,
    },
    {
        size: "xlarge",
        mediaQuery: "(min-width: 1281px) and (max-width: 1920px)",
        width: 1920,
    },
    {
        size: "xlarge",
        mediaQuery: "(min-width: 1281px) and (max-width: 1920px)",
        width: 1920,
    },
    {
        size: "xxlarge",
        mediaQuery: "(min-width: 1921px)",
        width: 3840,
    },
];

export const PRELOAD = "PRELOAD";
export const LAZY = "LAZY";
export const SERVER = "SERVER";
export type LoadingMode =
    | typeof PRELOAD
    | typeof LAZY
    | typeof SERVER
    | undefined;

export interface LoadingModeParameters {
    preloadPoster: boolean;
    isInServerMode: boolean;
    mediaUrl: string;
}
