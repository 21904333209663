import { LINK, NONE } from "@hermes/aphrodite/dynamic-box";
import { Asset } from "@hermes/api-model-asset";
import { ProductTemplateType } from "@hermes/api-model-product";
import { AssetMediaType } from "@hermes/utils-generic/constants";

import {
    cleanProductState,
    defaultImageGalleryPosition,
    fetchCrossSellProduct,
    fetchCrossSellProductFailure,
    fetchCrossSellProductSuccess,
    fetchProductFailure,
    fetchProductSuccess,
    openSelectorToggle,
    resetSizeSelectedVariants,
    resetVariant,
    resetVariantSelectedBoxType,
    selectVariant,
    showMoreVariant,
    toggleZoom,
} from "../actions/product-page.actions";
import { VariantExpandedState } from "../types/product-page.types";

import { fetchAppleWatchProductSuccess } from "./apple-watch/apple-watch-product-page.reducer";
import { fetchBeltkitProductSuccess } from "./beltkit/beltkit-product-page.reducer";
import { fetchBikiniProductSuccess } from "./bikini/bikini-product-page.reducer";
import { fetchDoubleFragranceProductSuccess } from "./double-fragrance/double-fragrance-product-page.reducer";
import { fetchLookProductSuccess } from "./look/look-product-page.reducer";
import { initialProductState, ProductPageState } from "./product-page.state";
import { toggleReducer } from "./reducer.helper";
import { fetchSimpleProductSuccess } from "./simple/simple-product-page.reducer";

export const setIsLoadingReducer = (
    state: ProductPageState,
): ProductPageState => ({
    ...state,
    isLoading: true,
});

export const fetchProductSuccessReducer = (
    state: ProductPageState,
    action: ReturnType<typeof fetchProductSuccess>,
): ProductPageState => {
    const product = action.data;

    switch (product.templateType) {
        case ProductTemplateType.Simple: {
            return fetchSimpleProductSuccess(state, product);
        }
        case ProductTemplateType.Beltkit: {
            return fetchBeltkitProductSuccess(state, product);
        }
        case ProductTemplateType.AppleWatch: {
            return fetchAppleWatchProductSuccess(state, product);
        }
        case ProductTemplateType.Giftset: {
            // only basic mapping - specific done in giftset-product-page.reducer.ts;
            return fetchSimpleProductSuccess(state, product);
        }
        case ProductTemplateType.DoubleFragrance: {
            return fetchDoubleFragranceProductSuccess(state, product);
        }
        case ProductTemplateType.Look: {
            return fetchLookProductSuccess(state, product);
        }
        case ProductTemplateType.Bikini: {
            return fetchBikiniProductSuccess(state, product);
        }
        default: {
            return { ...state, isLoading: false };
        }
    }
};

export const fetchProductFailureReducer = (
    state: ProductPageState,
    _action: ReturnType<typeof fetchProductFailure>,
): ProductPageState => ({ ...state, isLoading: false });

export const fetchCrossSellProductReducer = (
    state: ProductPageState,
    _action: ReturnType<typeof fetchCrossSellProduct>,
): ProductPageState => ({
    ...state,
});

export const fetchCrossSellProductSuccessReducer = (
    state: ProductPageState,
    action: ReturnType<typeof fetchCrossSellProductSuccess>,
): ProductPageState => ({
    ...state,
    crossSell: action.data,
});

export const fetchCrossSellProductFailureReducer = (
    state: ProductPageState,
    _action: ReturnType<typeof fetchCrossSellProductFailure>,
): ProductPageState =>
    // Non-critical data, return empty by default
    ({
        ...state,
        crossSell: undefined,
    });

export const selectVariantReducer = (
    state: ProductPageState,
    action: ReturnType<typeof selectVariant>,
): ProductPageState => {
    // Base case is to reset the addToCart error where the selected variant is changed
    const indexToUpdateVariant = action.position ?? 0;

    const neoSelectedVariants = [...state.selectedVariants];
    neoSelectedVariants[indexToUpdateVariant] = {
        ...state.selectedVariants[indexToUpdateVariant],
        [action.variantType]: action.variantItem,
    };
    return {
        ...state,
        selectedVariants: neoSelectedVariants,
    };
};

export const resetSizeSelectedVariantsReducer = (
    state: ProductPageState,
    _action: ReturnType<typeof resetSizeSelectedVariants>,
): ProductPageState => ({
    ...state,
    selectedVariants: state.selectedVariants.filter(
        // eslint-disable-next-line unicorn/explicit-length-check
        (selectedVariant) => !selectedVariant.size,
    ),
});

export const resetVariantReducer = (
    state: ProductPageState,
    action: ReturnType<typeof resetVariant>,
): ProductPageState => {
    const neoSelectedVariants = [...state.selectedVariants];
    neoSelectedVariants[action.position] = {};

    return {
        ...state,
        selectedVariants: neoSelectedVariants,
    };
};

export const showMoreVariantReducer = (
    state: ProductPageState,
    action: ReturnType<typeof showMoreVariant>,
): ProductPageState => ({
    ...state,
    variantExpanded: {
        ...state.variantExpanded,
        [action.data.id]: !state.variantExpanded?.[action.data.id],
    } as VariantExpandedState,
});

/**
 * When user click on toggle
 * - switch this toggle status
 * - close all other
 */
export const openSelectorToggleReducer = (
    state: ProductPageState,
    action: ReturnType<typeof openSelectorToggle>,
): ProductPageState => {
    const switchedToggle = toggleReducer(
        state.variantExpanded,
        action.toggleKey,
    );
    return {
        ...state,
        variantExpanded: {
            ...switchedToggle,
        },
    };
};

export const toggleZoomReducer = (
    state: ProductPageState,
    action: ReturnType<typeof toggleZoom>,
): ProductPageState => {
    const currentAssetUrl =
        action.zoomAssets && action.currentIndex !== undefined
            ? action.zoomAssets[action.currentIndex].url
            : undefined;
    const assetsWithoutVideo = action.zoomAssets
        ? action.zoomAssets.filter(
              (asset: Asset) => asset.type === AssetMediaType.Image,
          )
        : [];
    return {
        ...state,
        zoomModal: {
            zoomStatus: action.zoomStatus,
            currentIndex: action.currentIndex,
            zoomAssets: assetsWithoutVideo,
            isTopLevelProduct: action.isTopLevelProduct,
            currentAssetUrl,
        },
    };
};

export const cleanProductStateReducer = (
    _state: ProductPageState,
    _action: ReturnType<typeof cleanProductState>,
): ProductPageState => initialProductState;

export const defaultImageGalleryPositionReducer = (
    state: ProductPageState,
    _action: ReturnType<typeof defaultImageGalleryPosition>,
): ProductPageState => ({
    ...state,
    variantSelectedBoxType: LINK,
});

export const resetVariantSelectedBoxTypeReducer = (
    state: ProductPageState,
    _action: ReturnType<typeof resetVariantSelectedBoxType>,
): ProductPageState => ({
    ...state,
    variantSelectedBoxType: NONE,
});
