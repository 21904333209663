import { CommonModule } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core";

import { LoaderSpinnerComponent } from "@hermes/aphrodite/loader-spinner";

import { iconButtonAnimations } from "./icon-button.animation";

/**
 * This component implements the Hermes button with only icon presentation.
 * Usage:
 * ```
 *  <h-icon-button
 *             [id]="id"
 *             [name]="'my name'"
 *             [label]="'my label'"
 *             [changeOpacityOnHover]="false"
 *             [boxShadow]="true"
 *             [border]="true"
 *             [backgroundColor]="primary"
 *             [iconHeight]="icon height"
 *             (pressed)="pressed()"
 *         >
 *      <h-svg-icon [name]="'play-media'" style="stroke: black;"></h-svg-icon>
 * </h-icon-button>
 * ```
 */

export const SMALL = "small";
export const MEDIUM = "medium";
export const LARGE = "large";
export type IconButtonSize = typeof SMALL | typeof MEDIUM | typeof LARGE;

export const SVG_ONLY = "SVG_ONLY";
export const SVG_WITH_TEXT_RIGHT = "SVG_WITH_TEXT_RIGHT";
export const SVG_WITH_TEXT_LEFT = "SVG_WITH_TEXT_LEFT";
export type Appearance =
    | typeof SVG_ONLY
    | typeof SVG_WITH_TEXT_RIGHT
    | typeof SVG_WITH_TEXT_LEFT;

export const PRIMARY = "primary";
export const SECONDARY = "secondary";
export const SECONDARY_DARK = "secondary-dark";
export const SECONDARY_LIGHT = "secondary-light";
export const SECONDARY_EXTRA_LIGHT = "secondary-extra-light";
export type BackgroundColor =
    | typeof PRIMARY
    | typeof SECONDARY
    | typeof SECONDARY_DARK
    | typeof SECONDARY_LIGHT
    | typeof SECONDARY_EXTRA_LIGHT;
export const GAP_WITH_TEXT: number = 8;
export const GAP_WITHOUT_TEXT: number = 0;

@Component({
    selector: "h-icon-button",
    templateUrl: "./icon-button.component.html",
    styleUrls: ["./icon-button.component.scss"],
    animations: [iconButtonAnimations],
    imports: [CommonModule, LoaderSpinnerComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class IconButtonComponent {
    @ViewChild("iconButton")
    public iconButton!: ElementRef<HTMLButtonElement>;

    @Input()
    public id!: string;

    @Input()
    public isKeyboardNavigationActive: boolean = false;

    @Input()
    public name!: string;

    @Input()
    public label!: string;

    @Input()
    public changeOpacityOnHover: boolean = false;

    @Input()
    public boxShadow: boolean = false;

    @Input()
    public border: boolean = true;

    @Input()
    public describedBy?: string;

    @Input()
    public borderRadius: boolean = true;

    /**
     * Button background color
     */
    @Input()
    public backgroundColor: BackgroundColor = PRIMARY;

    /**
     * Change the gap between the text and the icon
     */
    @Input()
    public gapBetweenSvgAndText?: number;

    /**
     * Change default button background size (default small = 30px)
     */
    @Input()
    public buttonSize: IconButtonSize = MEDIUM;

    @Input()
    public iconSize: string = "unset";

    @Input()
    public lineHeight: string = "normal";

    @Input()
    public textBoldUppercase: boolean = false;

    // make icon button inaccessible
    @Input()
    public disableTabIndex: boolean = false;

    @Input()
    public isLoading: boolean = false;

    @Input()
    public hasTransition: boolean = false;

    @Output()
    public pressed: EventEmitter<Event> = new EventEmitter<Event>();

    @Output()
    public focusOut: EventEmitter<void> = new EventEmitter<void>();

    public isSvgWithText: boolean = false;

    public smallSize = SMALL;
    public mediumSize = MEDIUM;
    public svg_with_text_right = SVG_WITH_TEXT_RIGHT;
    public svg_with_text_left = SVG_WITH_TEXT_LEFT;
    public svg_only = SVG_ONLY;

    private prAppearance?: Appearance = SVG_ONLY;

    public get appearance(): Appearance | undefined {
        return this.prAppearance;
    }

    @Input()
    public set appearance(appearance: Appearance) {
        this.prAppearance = appearance;
        this.isSvgWithText = [SVG_WITH_TEXT_RIGHT, SVG_WITH_TEXT_LEFT].includes(
            appearance,
        );
        if (!this.gapBetweenSvgAndText) {
            this.gapBetweenSvgAndText = this.isSvgWithText
                ? GAP_WITH_TEXT
                : GAP_WITHOUT_TEXT;
        }
    }
}
