import { Injectable } from "@angular/core";

import { Observable, map } from "rxjs";

import { Asset } from "@hermes/api-model-asset";
import { PRODUCT_PAGE_IMAGE_SIZE } from "@hermes/fragments/product-utils";
import { AnalyticsService } from "@hermes/utils/analytics";

import { AssetMediaType } from "@hermes/utils-generic/constants";

import { CarouselClickEvent } from "../events/carousel-click.event";
import { ProductPageFacade } from "../facades/product-page.facade";
import { ProductImageSeoUrlPipe } from "../pipes/product-image-seo-url/product-image-seo-url.pipe";

@Injectable()
export class ProductPageAssetService {
    public transformedAssets$: Observable<Asset[]> =
        this.productPageFacade.currentProduct$.pipe(
            map((product) =>
                this.transformAssets(
                    product.slug,
                    product.templateType,
                    product.assets,
                    product.title,
                ),
            ),
        );

    constructor(
        private productImageSeoUrlPipe: ProductImageSeoUrlPipe,
        private productPageFacade: ProductPageFacade,
        private analyticsService: AnalyticsService,
    ) {}

    /**
     * This function transform the assets urls to a SEO friendly format and apply a custom size for them
     * @param productAssets product assets to be transformed
     * @param productSlug product slug used to build the asset url
     * @param productTemplateType used in the **ProductImageSeoUrlPipe** to choose the correct SEO format
     * @param imageSize image size definition page, default at 900px
     * @returns **Asset[]** : transformed assets in seo format
     */
    public transformAssets(
        productSlug: string,
        productTemplateType: string,
        productAssets: Asset[] = [],
        productTitle: string = "",
        imageSize: number = PRODUCT_PAGE_IMAGE_SIZE,
    ): Asset[] {
        return productAssets
            ? productAssets.map((asset: Asset) => {
                  if (asset.type === AssetMediaType.Image) {
                      const newUrl = this.productImageSeoUrlPipe.transform(
                          asset.url,
                          {
                              slug: productSlug,
                              imageSize,
                              templateType: productTemplateType,
                          },
                      );
                      return {
                          ...asset,
                          url: newUrl,
                          title: productTitle,
                      } as Asset;
                  }
                  return asset;
              })
            : [];
    }

    /** Manage the sending of events analytics when changing image */
    public sendAnalyticsTagForCarouselChangeImage(
        currentIndex: number,
        productTemplateType: string,
        assets: Asset[],
        currentVariantSku: string = "",
        isTopLevelProduct: boolean = true,
        productSku?: string,
    ): void {
        this.analyticsService.sendData(
            new CarouselClickEvent({
                imageType: "product",
                templateType: productTemplateType,
                assetType: assets.length > 0 ? assets[currentIndex]?.type : "",
                currentVariantSku,
                isTopLevelProduct,
                currentIndex,
                sku: productSku,
            }),
        );
    }
}
