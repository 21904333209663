import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter, map, Observable, switchMap } from "rxjs";

import { fetchFeatureFlags } from "../actions/feature-flag-actions";
import {
    FeatureFlagDefinition,
    FeatureFlagName,
    FEATURE_FLAGS,
} from "../constants/feature-flags.constant";
import { FeatureFlagState, NO_FLAGS } from "../reducers/feature-flag.state";
import {
    selectFlags,
    selectIsLoaded,
} from "../selectors/feature-flag.selector";

@Injectable()
export class FeatureFlagFacade {
    /**
     * Describes the loading status of FF flags
     */
    public isLoaded$: Observable<boolean> = this.store.select(selectIsLoaded);

    constructor(private store: Store<FeatureFlagState>) {}

    public fetchFeatureFlags(flags: FeatureFlagDefinition[]): void {
        this.store.dispatch(fetchFeatureFlags({ flags }));
    }

    public isActivated(flagName: FeatureFlagName): Observable<boolean> {
        return this.isLoaded$.pipe(
            filter((isLoaded) => isLoaded), // Ensure flags are loaded
            switchMap(() =>
                this.store.select(selectFlags).pipe(
                    map((flags) => {
                        if (flags === NO_FLAGS) {
                            /**
                             * Thanks to the strong typing we are sure that the ID of the key is present in the array.
                             *
                             * So we force the type (`as boolean`)
                             */
                            return FEATURE_FLAGS.find(
                                (flag) => flag.flagName === flagName,
                            )?.defaultValue as boolean;
                        }
                        return flags[flagName];
                    }),
                ),
            ),
        );
    }
}
