@if (!videoReady) {
<div class="poster-container">
    <!-- Before the video is ready we display the poster in its place  -->
    <div class="poster-image">
        <ng-content></ng-content>
    </div>
</div>
}

<ng-template #PlayerAccessibility>
    @if (videoReady) {
    <div hSrOnlyNoSnippet [attr.aria-live]="'polite'">
        @if (video?.paused) {
        <span i18n="@@hermes.accessibility.play-video">Play video</span>
        } @if (!video?.paused) {
        <span i18n="@@hermes.accessibility.pause-video">Pause video</span>
        }
    </div>
    }
</ng-template>

<ng-template #MuteAccessibility>
    @if (videoReady) {
    <div hSrOnlyNoSnippet [attr.aria-live]="'polite'">
        @if (!video?.muted) {
        <span i18n="@@hermes.accessibility.mute">Mute</span>
        } @if (video?.muted) {
        <span i18n="@@hermes.accessibility.unmute">Enable sound</span>
        }
    </div>
    }
</ng-template>

<div
    [class.video-ready]="videoReady"
    [class.video-loaded]="videoLoaded$.value"
    [class.gradient]="displayGradient"
    [class.disable-pointer-events]="!showMuteButtons && isLightMode"
    class="video-button"
    hDetectViewport
    (isInViewport)="handleViewportVisibilityChange($event)"
    [threshold]="detectViewportTreeshold"
    [detectOnce]="detectViewportOnce"
    (click)="playPauseVideo()">
    <ng-template [ngTemplateOutlet]="PlayerAccessibility"></ng-template>
    <video
        #video
        [attr.aria-hidden]="isAriaHidden"
        preload="none"
        [autoplay]="isAutoPlay"
        class="video-js vjs-big-play-centered"
        [ngClass]="videoRatio ? 'vjs-' + videoRatio : 'vjs-fluid fix-height'"></video>
</div>

<div #buttonContainer class="btn-container">
    @if (isLightMode && (videoReady || alwaysShowControls) ) {
    <span class="btn-span {{ controlPosition }}-mode  {{ controlSize }}-size">
        @if (showMuteButtons) {
        <h-icon-button
            id="mute-unmute-btn"
            name="mute-unmute-btn"
            class="mute-unmute-btn"
            [changeOpacityOnHover]="true"
            [border]="false"
            [disableTabIndex]="disableControlTabIndex"
            [lineHeight]="'0'"
            [buttonSize]="controlSize"
            [boxShadow]="buttonShadow"
            [iconSize]="iconSize + 'px'"
            [ngClass]="{
                'hide-icon-btn': hidePlayPauseButton,
                'keyboard-navigation': displayGradient && (layoutFacade.keyboardNavigation$ | async)
            }"
            (pressed)="muteUnmuteVideo($event)">
            <div text>
                <ng-template [ngTemplateOutlet]="MuteAccessibility"></ng-template>
            </div>
            <h-svg-icon class="svg-mute-icon" [name]="svgMuteClass"></h-svg-icon>
        </h-icon-button>
        }

        <h-icon-button
            id="play-pause-btn"
            name="play-pause-btn"
            [changeOpacityOnHover]="true"
            [border]="false"
            [disableTabIndex]="disableControlTabIndex"
            [lineHeight]="'0'"
            [boxShadow]="buttonShadow"
            [buttonSize]="controlSize"
            [iconSize]="iconSize + 'px'"
            [ngClass]="{
                'hide-icon-btn': hidePlayPauseButton,
                'keyboard-navigation': displayGradient && (layoutFacade.keyboardNavigation$ | async)
            }"
            (pressed)="playPauseVideo($event)">
            <div text>
                <ng-template [ngTemplateOutlet]="PlayerAccessibility"></ng-template>
            </div>
            <h-svg-icon [name]="svgIconClass"></h-svg-icon>
        </h-icon-button>
    </span>
    }
</div>
