@if (menuEntry$ | async; as menu) {
<nav
    #menuBarContainer
    [attr.role]="'navigation'"
    i18n-aria-label="@@hermes.accessibility.navigation_menu"
    aria-label="Navigation menu"
    (mouseleave)="onMouseLeave()"
    (keydown.arrowleft)="focusLeft()"
    (keydown.arrowright)="focusRight()"
    (keydown.home)="setFocusToFistItem()"
    (keydown.end)="setFocusToLastItem()">
    <ul
        class="menu-bar-container"
        [ngStyle]="{
        '--header-menu-height.px': headerMenuHeight,
    }">
        @for (subMenuEntry of menu.menuEntryList; track subMenuEntry; let index = $index) { @if (subMenuEntry.type !==
        menuEntryType.separatorMenuEntry) {
        <li class="item">
            <h-menu-parent-category
                [menuEntry]="subMenuEntry"
                [focused]="parentCategoryFocusedIndex() === index"
                [onCursorLeaveMenuBar$]="mousLeaveEventsSubject"
                (keydown)="onKeyDown($event, index)"
                (keyup)="onKeyUp($event)"
                [isFirstCategory]="index === 0"
                [isLastCategory]="lastCategoryHasOpenSubMenu()"></h-menu-parent-category>
        </li>
        } @else {
        <div class="menu-separator"></div>
        } }
    </ul>
</nav>
}
