@if (mediaPicture) { @switch (loadingMode) { @case ('PRELOAD') {
<picture>
    <ng-template [ngTemplateOutlet]="sourceTemplate"></ng-template>
    <img class="full-parent" [src]="mediaPicture.desktopImage" [alt]="imageAlt" fetchpriority="high" />
    <ng-template [ngTemplateOutlet]="titleTemplate"></ng-template>
</picture>
} @case ('LAZY') {
<picture>
    <ng-template [ngTemplateOutlet]="sourceTemplate"></ng-template>
    <img class="full-parent" [src]="mediaPicture.desktopImage" [alt]="imageAlt" loading="lazy" />
    <ng-template [ngTemplateOutlet]="titleTemplate"></ng-template>
</picture>
} @case ('SERVER') {
<noscript>
    <img class="full-parent" [src]="mediaPicture.desktopImage" [alt]="imageAlt" />
    <ng-template [ngTemplateOutlet]="titleTemplate"></ng-template>
</noscript>
} } }

<ng-template #titleTemplate>
    @if (title) {
    <figcaption class="media-title-overlay">
        <div class="title"> {{ title }}</div>
    </figcaption>
    }
</ng-template>

<ng-template #sourceTemplate>
    @if (mediaPicture) { @for (mediaQueryOptions of mediaQueriesOptions ?? defaultMediaQueriesOptions; track
    trackBySize($index, mediaQueryOptions)) {
    <source
        [attr.media]="mediaQueryOptions.mediaQuery"
        [attr.srcset]="
            mediaUrl
                | hPosterUrl
                    : {
                          mobileUrl: mediaPicture.mobileImage,
                          size: mediaQueryOptions.size,
                          width: mediaQueryOptions.width
                      }
        " />
    } }
</ng-template>
