import { HttpInterceptor } from "@angular/common/http";
import { NgModule, Type } from "@angular/core";
import { mapToResolve, RouterModule } from "@angular/router";

import { ShellFacadeGuard } from "@hermes/fragments/shell";
import {
    CreateUserSessionOn401UnauthorizedInterceptor,
    RedirectOn404NotFoundInterceptor,
    RedirectOn410GoneInterceptor,
} from "@hermes/interceptors";
import {
    CustomerSessionResolver,
    EmptyComponentComponent,
    ErrorPageComponent,
    LocalePrefixGuard,
    NotFoundPageComponent,
    RouterComponentsModule,
    XsrfTokenResolver,
} from "@hermes/router-components";
import { ShopsService } from "@hermes/shop-utils";
import { BasketStateModule } from "@hermes/states/basket";
import {
    HermesRoutes,
    LAYOUT_MARGIN_ADAPTATIVE,
    LAYOUT_MARGIN_FIXE,
    SHELL_APPEARANCE_FULL,
    SHELL_APPEARANCE_LIGHT,
    SHELL_APPEARANCE_NONE,
} from "@hermes/utils-generic/constants";
import { ImageUrlPipe } from "@hermes/utils-generic/pipes/image-url";

import { ShopAssetUrlPipe } from "libs/hermes-web/pages/store-page/src/lib/store/pipes/shop-asset-url.pipe";

import { StorePageResolver } from "libs/hermes-web/pages/store-page/src/lib/store/resolvers/store-page.resolver";

export const routes: HermesRoutes[] = [
    // product page
    {
        path: "product",
        loadChildren: () =>
            import("@hermes/pages/product").then((m) => m.ProductPageModule),
        data: {
            layoutType: LAYOUT_MARGIN_FIXE,
        },
    },
    // Home page
    {
        path: "",
        pathMatch: "full",
        loadChildren: () =>
            import("@hermes/pages/home-page").then((m) => m.HomePageModule),
    },
    // Grid page (Category)
    {
        path: "category",
        loadChildren: () =>
            import("./pages/grid-page/grid-page.module").then(
                (m) => m.GridPageModule,
            ),
    },
    // Grid page (Search)
    {
        path: "search/",
        // eslint-disable-next-line sonarjs/no-identical-functions
        loadChildren: () =>
            import("./pages/grid-page/grid-page.module").then(
                (m) => m.GridPageModule,
            ),
    },

    // Product personalization generic page
    {
        path: "product/:productCategory/config/:slug/",
        loadChildren: () =>
            import(
                "./pages/personalization-page/product-personalization/product-personalization.module"
            ).then((m) => m.ProductPersonalizationModule),
    },
    // Product pages
    {
        path: "product",
        loadChildren: () =>
            import("./pages/product-page/product-page.module").then(
                (m) => m.ProductPageModule,
            ),
    },
    // Cart page
    {
        path: "cart/",
        loadChildren: () =>
            import("./pages/cart-page/cart-page.module").then(
                (m) => m.CartPageModule,
            ),
        data: {
            shell: SHELL_APPEARANCE_LIGHT,
            layoutType: LAYOUT_MARGIN_ADAPTATIVE,
        },
    },
    // Login page
    {
        path: "login/",
        loadChildren: () =>
            import("@hermes/pages/login-page").then((m) => m.LoginPageModule),
        data: {
            layoutType: LAYOUT_MARGIN_ADAPTATIVE,
        },
    },
    // Checkout login page
    {
        path: "checkout/login/",
        loadChildren: () =>
            import("@hermes/pages/checkout-login-page").then(
                (m) => m.CheckoutLoginPageModule,
            ),
        data: {
            shell: SHELL_APPEARANCE_LIGHT,
            layoutType: LAYOUT_MARGIN_ADAPTATIVE,
        },
    },
    // Account creation page
    {
        path: "account/create/",
        loadChildren: () =>
            import("@hermes/pages/account-creation-page").then(
                (m) => m.AccountCreationPageModule,
            ),
        data: {
            layoutType: LAYOUT_MARGIN_ADAPTATIVE,
        },
    },
    // Account page
    {
        path: "account/",
        loadChildren: () =>
            import("@hermes/pages/account").then((m) => m.AccountPageModule),
    },
    // Checkout page
    {
        path: "checkout/",
        loadChildren: () =>
            import("./pages/checkout-page/checkout-page.module").then(
                (m) => m.CheckoutPageModule,
            ),
        data: {
            shell: SHELL_APPEARANCE_LIGHT,
            layoutType: LAYOUT_MARGIN_ADAPTATIVE,
        },
    },
    // Contact us page
    {
        path: "contact-us/",
        loadChildren: () =>
            import("@hermes/pages/contact-us-page").then(
                (m) => m.ContactUsPageModule,
            ),
        data: {
            layoutType: LAYOUT_MARGIN_ADAPTATIVE,
        },
    },
    // Order confirmation page
    {
        path: "order/:orderId/",
        loadChildren: () =>
            import(
                "./pages/order-confirmation-page/order-confirmation-page.module"
            ).then((m) => m.OrderConfirmationPageModule),
        data: {
            shell: SHELL_APPEARANCE_LIGHT,
            backButton: "home",
            layoutType: LAYOUT_MARGIN_ADAPTATIVE,
        },
    },
    // Change password page
    {
        path: "change-password/",
        loadChildren: () =>
            import("@hermes/pages/change-password").then(
                (m) => m.ChangePasswordPageModule,
            ),
        data: {
            layoutType: LAYOUT_MARGIN_ADAPTATIVE,
        },
    },
    {
        path: "payment-validation",
        loadChildren: () =>
            import("@hermes/pages/payment-validation-page").then(
                (m) => m.PaymentValidationPageModule,
            ),
        data: {
            shell: SHELL_APPEARANCE_NONE,
        },
    },
    {
        path: "find-store/:country/:city/:id/",
        loadComponent: () =>
            import("@hermes/pages/store").then(
                (m) => m.StorePageContainerComponent,
            ),
        providers: [
            StorePageResolver,
            ShopsService,
            ShopAssetUrlPipe,
            ImageUrlPipe,
        ],
        resolve: { store: mapToResolve(StorePageResolver) },
    },
    {
        path: "find-store/:country/:city/:id/appointment/",
        loadChildren: () =>
            import("@hermes/pages/appointment-in-store").then(
                (m) => m.AppointmentInStoreModule,
            ),
    },
    {
        path: "find-store",
        loadChildren: () =>
            import("@hermes/pages/shop-locator").then(
                (m) => m.ShopLocatorPageModule,
            ),
    },
    // Story page
    {
        path: "content",
        loadChildren: () =>
            import("@hermes/pages/story-page-deprecated").then(
                (m) => m.StoryPageModule,
            ),
    },
    // Story page from Discovery
    {
        path: "discovery",
        loadChildren: () =>
            import("@hermes/pages/story-page").then((m) => m.StoryPageModule),
    },
    // After sales page
    {
        path: "aftersales",
        loadChildren: () =>
            import("@hermes/pages/after-sales").then((m) => m.AfterSalesModule),
    },
    // Agec page
    {
        path: "environment",
        loadChildren: () =>
            import("@hermes/pages/agec-page").then((m) => m.AgecPageModule),
    },
    // Legal Page
    {
        path: "legal",
        loadChildren: () =>
            import("@hermes/pages/legal").then((m) => m.LegalPageModule),
        data: {
            layoutType: LAYOUT_MARGIN_ADAPTATIVE,
        },
    },
    // Faq page
    {
        path: "faq",
        loadChildren: () =>
            import("@hermes/pages/faq-page").then((m) => m.FaqPageModule),
    },
    // Fragrance finder page
    {
        path: "fragrance-finder",
        loadChildren: () =>
            import("@hermes/pages/fragrance-finder-page").then(
                (m) => m.FragranceFinderPageModule,
            ),
    },
    // Empty page (for SSR)
    {
        path: "empty-page",
        component: EmptyComponentComponent,
    },
    // Test page
    {
        path: "test-page/",
        loadChildren: () =>
            import("./pages/test-page/test-page.module").then(
                (m) => m.TestPageModule,
            ),
    },
    // 404 Page
    {
        path: "404-error",
        component: NotFoundPageComponent,
    },
    {
        path: "personalization-landing-page",
        loadChildren: () =>
            import(
                "./pages/personalization-page/perso-landing-page/perso-landing-page.module"
            ).then((m) => m.PersoLandingPageModule),
    },
    // Back in stock unsubscribe page
    {
        path: "back-in-stock/unsubscribe/",
        loadChildren: () =>
            import("@hermes/pages/back-in-stock-unsubscribe").then(
                (m) => m.BackInStockUnsubscribePageModule,
            ),
    },
    // 500 page
    {
        path: "500-error",
        component: ErrorPageComponent,
    },
    // Unmatched : redirect to 404 page
    {
        path: "**",
        redirectTo: "404-error",
    },
];

type Path = (typeof routes)[number]["path"];

export const interceptors: Record<Path, Array<Type<HttpInterceptor>>> = {
    // eslint-disable @typescript-eslint/naming-convention
    "404-error": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "environment/": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    legal: [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "empty-page": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "test-page/": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "personalization-landing-page": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "500-error": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "**": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    category: [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "search/": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "product/:productCategory/config/:slug/": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    product: [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn410GoneInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "cart/": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "login/": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "checkout/login/": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "account/create/": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "checkout/": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "contact-us/": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "order/:orderId/": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "payment-validation": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "find-store/:country/:city/:id/": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "find-store/:country/:city/:id/appointment/": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    "find-store": [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    content: [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    aftersales: [
        CreateUserSessionOn401UnauthorizedInterceptor,
        RedirectOn404NotFoundInterceptor,
    ],
    // eslint-enable @typescript-eslint/naming-convention
};

/**
 * Global routing module.
 */
@NgModule({
    exports: [RouterModule],
    imports: [
        BasketStateModule,
        RouterComponentsModule,
        RouterModule.forRoot(
            [
                {
                    // check url for locale prefix for every route.
                    path: "",
                    canActivateChild: [LocalePrefixGuard, ShellFacadeGuard],
                    resolve: {
                        customerSession: CustomerSessionResolver,
                        xsrf: XsrfTokenResolver,
                    },
                    children: routes,
                    data: {
                        shell: SHELL_APPEARANCE_FULL,
                    },
                },
            ],
            {
                initialNavigation: "enabledBlocking",
                enableTracing: false,
            },
        ),
    ],
    providers: [],
})
export class AppRoutingModule {}
