import {
    Component,
    ElementRef,
    Input,
    ViewChild,
    OnInit,
    ChangeDetectorRef,
    OnDestroy,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { Subscription } from "rxjs";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import {
    MenuAnalyticsService,
    MenuItemType,
    MenuUtilsService,
} from "@hermes/fragments/menu-shared";
import { MenuEntryItem } from "@hermes/states/menu";

export const MENU_LINK_LVL1 = "menu-link-lvl1";
export const MENU_LINK_LVL2 = "menu-link-lvl2";
export const MENU_LINK_LVL3 = "menu-link-lvl3";
export type MenuLinkLevel =
    | typeof MENU_LINK_LVL1
    | typeof MENU_LINK_LVL2
    | typeof MENU_LINK_LVL3;

@Component({
    selector: "h-menu-bar-link",
    templateUrl: "./menu-bar-link.component.html",
    styleUrl: "./menu-bar-link.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuBarLinkComponent implements OnInit, OnDestroy {
    @ViewChild("anchor")
    public anchor!: ElementRef<HTMLAnchorElement>;

    @Input({ required: true })
    public menuEntry!: MenuEntryItem;

    @Input({ required: true })
    public menuLinkLevel!: MenuLinkLevel;

    @Input()
    public primaryEntry?: MenuEntryItem;

    @Input()
    public secondaryEntry?: MenuEntryItem;

    @Input()
    public anchorId!: string;

    @Output()
    public menuLinkClicked: EventEmitter<void> = new EventEmitter<void>();

    public type = MenuItemType.Link;

    public currentUrl?: string;
    public menuEntryPath?: string;

    private subscription = new Subscription();

    constructor(
        public menuUtilsService: MenuUtilsService,
        public menuAnalyticsService: MenuAnalyticsService,
        private router: Router,
        public detector: ChangeDetectorRef,
        public layoutFacade: LayoutFacade,
    ) {}

    public ngOnInit(): void {
        this.menuEntryPath =
            this.menuUtilsService.getMenuEntryPathWithoutLocale(
                this.menuEntry.path,
            );

        this.subscription.add(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.currentUrl = event.urlAfterRedirects.replace(
                        /#\|$/,
                        "",
                    );

                    this.detector.detectChanges();
                }
            }),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getFocusableElement(): HTMLElement {
        return this.anchor.nativeElement;
    }

    public click(event: MouseEvent | Event): void {
        event.preventDefault();
        event.stopPropagation();

        this.menuUtilsService.navigateToMenuLink(this.menuEntry);

        this.menuLinkClicked.emit();

        this.menuAnalyticsService.sendAnalyticsOnMenuLinkClick(
            this.primaryEntry,
            this.secondaryEntry,
            this.menuEntry,
        );
    }
}
