@if (media) { @if (videoUrl) { @if (media) {
<h-video
    [showMuteButtons]="showSoundControlButton"
    [displayGradient]="displayGradient"
    [isLightMode]="isLightMode"
    [videoUrl]="videoUrl"
    [mobileVideoUrl]="mobileVideoUrl"
    [alwaysShowControls]="media.alwaysShowControls"
    [videoLoopMuteAutoplay]="media.videoLoopMuteAutoplay"
    [videoRatio]="videoRatio"
    [videoName]="mediaName">
    <ng-template [ngTemplateOutlet]="pictureTemplate"></ng-template>
</h-video>
} @if (showFigCaption && media) {
<h-media-captions [media]="media"></h-media-captions>
} } @if (!videoUrl && !mobileVideoUrl) {
<figure hBindSPANavigationForCTA role="figure" [attr.aria-label]="ariaLabel">
    @if (cta) {
    <a
        [attr.aria-label]="linkAriaLabel"
        (click)="mediaRedirection(cta)"
        class="media-link"
        data-cta-spa
        [href]="cta.path"
        [class.keyboard-navigation]="isKeyboardNavigation$ | async">
        <ng-template [ngTemplateOutlet]="pictureTemplate"></ng-template>
    </a>
    } @if (!cta) {
    <ng-template [ngTemplateOutlet]="pictureTemplate"></ng-template>
    } @if (showFigCaption) {
    <h-media-captions [media]="media"></h-media-captions>
    }
</figure>
} }

<ng-template #pictureTemplate>
    <h-media-picture
        [mediaPicture]="media"
        [preloadPoster]="preloadPoster"
        [mediaQueriesOptions]="mediaOptions"
        [title]="title"></h-media-picture>
</ng-template>
