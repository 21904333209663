@if (message) {
<div class="message-wrapper">
    @if (message.title) {
    <h2 class="title-large heading-2 display-light">
        {{ message.title }}
    </h2>
    }

    <p class="paragraph-medium title-small">
        {{ message.description }}
    </p>

    @if (message.action === 'refresh-page') {
    <p class="paragraph-medium">
        <a
            href=""
            id="notif-refresh-page-link"
            (click)="reloadCurrentPath()"
            i18n="@@hermes-global-translations.try-again">
            Try again
        </a>
    </p>
    } @if (message.action === 'back-to-home') {
    <p class="paragraph-medium">
        <a href="" id="notif-back-to-home-link" (click)="backToHome()" i18n="@@hermes-global-translations.home">
            Go back to home
        </a>
    </p>
    }
</div>
}
