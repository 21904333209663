import {
    Component,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
} from "@angular/core";

import { Meta } from "@angular/platform-browser";
import { Subscription } from "rxjs";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import {
    Context,
    LOCALE,
    preconnectLinks,
    chinaPreconnectLinks,
} from "@hermes/app-core";
import { isChina, isKorea, Locale } from "@hermes/locale";
import { RiskifiedBeaconService } from "@hermes/riskified-beacon-service";
import { DOWN, LEFT, RIGHT, TAB, UP } from "@hermes/utils-generic/constants";
import { HeadService } from "@hermes/utils-generic/services/head";

@Component({
    selector: "h-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();

    constructor(
        @Inject(LOCALE) private locale: Locale,
        private context: Context,
        private headService: HeadService,
        public layoutFacade: LayoutFacade,
        private meta: Meta,
        private riskifiedBeaconService: RiskifiedBeaconService,
    ) {}

    @HostListener("window:mousedown")
    public onmousedown(): void {
        this.layoutFacade.enableMouseNavigation();
    }

    @HostListener("window:keydown", ["$event"])
    public onkeyDown(event: KeyboardEvent): void {
        if ([TAB, UP, DOWN, LEFT, RIGHT].includes(event.key)) {
            this.layoutFacade.enableKeyboardNavigation();
        }
    }

    public ngOnInit(): void {
        this.initHeadTag();
        this.riskifiedBeaconService.executeService();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private initHeadTag(): void {
        if (this.context.isInServerMode()) {
            if (isKorea(this.locale)) {
                this.meta.addTag({
                    name: "naver-site-verification",
                    content: "47f4da7577b025b5e37093d3feded3f25c5ebc7f",
                });
            }
            if (isChina(this.locale)) {
                this.addPreconnectLinks(chinaPreconnectLinks);
            } else {
                this.addPreconnectLinks(preconnectLinks);
                this.headService.loadDataDome();
            }
        }
    }

    private addPreconnectLinks(tags: string[]): void {
        tags.forEach((link) => {
            this.headService.addTag({
                rel: "preconnect",
                href: `//${link}`,
            });
        });
    }
}
