@if (customerServiceConctact$ | async; as contacts) {
<div>
    @for (contact of contacts; track contact) {
    <div class="contact">
        <p class="description-block paragraph-small" [innerHTML]="contact | openingHours"></p>
        @if (contact.phone) {
        <div class="call-us" [attr.data-track-prefooter]="'call-us'">
            <a class="paragraph-small" href="tel:{{ contact.phone | phoneNumber }}" (click)="sendEventClickToCall()">{{
                contact.phone
            }}</a>
        </div>
        }
    </div>
    }

    <div class="email-us">
        @if (!isChina && showEmailUs) {
        <a
            class="paragraph-small"
            (click)="goToContactUsPage($event)"
            href="{{ baseHref }}/contact-us/"
            i18n-aria-label="@@hermes_account.email-us.aria-label"
            aria-label="Send an email to customer service"
            i18n="@@hermes_account.email-us">
            Email Us
        </a>
        }
    </div>
</div>
}
