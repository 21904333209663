import { Inject, Injectable, Optional } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from "@angular/router";

import type * as express from "express";
import { EMPTY, Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { Shop } from "@hermes/api-model-shop";
import { Context, LOCALE, REQUEST, RESPONSE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { ShopsService } from "@hermes/shop-utils";
import { ShellFacade } from "@hermes/states/shell";
import { redirectResponse } from "@hermes/utils/services/response";

import { SHELL_APPEARANCE_FULL } from "@hermes/utils-generic/constants";

import { RouterService } from "@hermes/utils-generic/services/router";

import { ShopAssetUrlPipe } from "../pipes/shop-asset-url.pipe";

@Injectable()
export class StorePageResolver {
    constructor(
        @Optional() @Inject(REQUEST) private request: express.Request,
        @Inject(RESPONSE) private response: express.Response,
        @Inject(LOCALE) private locale: Locale,
        private shopsService: ShopsService,
        private shopAssetUrlPipe: ShopAssetUrlPipe,
        private routerService: RouterService,
        private context: Context,
        private router: Router,
        private shellFacade: ShellFacade,
    ) {}

    /**
     * @returns Observable store-page api response
     */
    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<Shop> {
        this.shellFacade.setShell(SHELL_APPEARANCE_FULL);
        const [url, queryParameters] = state.url.slice(1).split("?");
        const shopId = (route.params.id as string).split("-").pop();

        if (!shopId) {
            this.redirectToNotFound(state);
            return EMPTY;
        }

        return this.shopsService.findShopById(shopId).pipe(
            tap((shop) => {
                if (url === shop.url) {
                    return;
                }

                const shopRedirectPath = `${shop.url}${
                    queryParameters ? `?${queryParameters}` : ""
                }`;

                if (this.context.isInServerMode()) {
                    redirectResponse({
                        request: this.request,
                        response: this.response,
                        newPath: `${this.locale.urlPrefix}/${shopRedirectPath}`,
                    });
                } else if (this.context.isInBrowserMode()) {
                    this.routerService.spaRedirect(`${shopRedirectPath}`);
                }
            }),
            map(
                (shop) =>
                    ({
                        ...shop,
                        assets: this.shopAssetUrlPipe.transform(shop.assets),
                    } as Shop),
            ),
            catchError(() => {
                this.redirectToNotFound(state);
                return EMPTY;
            }),
        );
    }

    private redirectToNotFound(state: RouterStateSnapshot): void {
        if (this.context.isInServerMode()) {
            // on server rendering mode, send a 404 not found HTTP status
            this.response.status(404);
        }
        // redirect to 404-page without changing the url
        this.router.navigate(["/404-error"], {
            skipLocationChange: true,
            replaceUrl: false,
            queryParams: {
                originalUrl: state.url,
            },
        });
    }
}
