import { Inject, Pipe, PipeTransform } from "@angular/core";

import { Asset } from "@hermes/api-model-asset";
import { Context, Settings, WINDOW } from "@hermes/app-core";
import { formatAssetUrl } from "@hermes/utils/helpers";
import {
    ImageConfig,
    ImageUrlPipe,
} from "@hermes/utils-generic/pipes/image-url";
export const IMAGE_CONFIG_DESKTOP: ImageConfig = {
    width: 1040,
    quality: "40",
    sourceSet: false,
};

export const DEFAUT_IMAGE_PATH = "store-general";

@Pipe({
    name: "shopAssetsUrl",
    standalone: true,
})
export class ShopAssetUrlPipe implements PipeTransform {
    constructor(
        private settings: Settings,
        private imagePipeUrl: ImageUrlPipe,
        private context: Context,
        @Inject(WINDOW) private window: Window,
    ) {}

    public transform(assets: Asset[]): Asset[] {
        const urls: string[] =
            assets?.length > 0
                ? assets.map((asset) => asset.url)
                : [`${this.settings.assetsUrlEditoImage}${DEFAUT_IMAGE_PATH}`];

        return urls
            .map((url) =>
                formatAssetUrl(
                    url,
                    this.context.isInBrowserMode()
                        ? this.window.navigator.userAgent
                        : undefined,
                ),
            )
            .map(
                (url) =>
                    new Asset(
                        this.imagePipeUrl.transform(url, IMAGE_CONFIG_DESKTOP),
                    ),
            );
    }
}
