<div class="sub-menu" (keydown.escape)="onEscapeKey($event)">
    <div class="sub-menu-wrapper">
        <div class="parent-category-title heading-2 regular-uppercase bold">
            <span [innerHTML]="menuEntry.name" role="heading" aria-level="1"></span>
        </div>

        <div class="sub-menu-content">
            @if (edito) {
            <div class="menu-edito">
                <h-menu-bar-edito
                    [edito]="edito"
                    [menuEntry]="menuEntry | asMenuEntryItemWithEdito"
                    (menuEditoClicked)="closeSubMenu.emit()"></h-menu-bar-edito>
            </div>
            }

            <ul class="sub-category-list">
                @for (subMenuEntry of menuEntry.menuEntryList; track subMenuEntry; let index = $index) { @if
                (subMenuEntry.type !== menuEntryType.separatorMenuEntry) {

                <li class="sub-category-block">
                    @if (subMenuEntry.menuEntryList.length > 0) {

                    <span class="sub-category-item label-medium-bold-uppercase">
                        {{ subMenuEntry.name }}
                    </span>

                    <ul>
                        @for (subSubMenuEntry of subMenuEntry.menuEntryList; track subSubMenuEntry; let subIndex =
                        $index) { @if (subSubMenuEntry.type !== menuEntryType.separatorMenuEntry) {
                        <li class="sub-subcategory-link paragraph-small">
                            <h-menu-bar-link
                                [menuEntry]="subSubMenuEntry"
                                [menuLinkLevel]="'menu-link-lvl3'"
                                [primaryEntry]="menuEntry"
                                [secondaryEntry]="subMenuEntry"
                                (keydown)="onKeyDown($event, index, subIndex)"
                                (menuLinkClicked)="closeSubMenu.emit()"></h-menu-bar-link>
                        </li>
                        } }
                    </ul>
                    } @else {

                    <h-menu-bar-link
                        class="sub-category-item label-medium-bold-uppercase"
                        [menuEntry]="subMenuEntry"
                        [menuLinkLevel]="'menu-link-lvl2'"
                        [primaryEntry]="menuEntry"
                        (keydown)="onKeyDown($event, index)"
                        (menuLinkClicked)="closeSubMenu.emit()"></h-menu-bar-link>
                    }
                </li>

                } }
            </ul>
        </div></div
    >
</div>
