import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import { MediaQueryOption } from "@hermes/aphrodite/media-picture";
import { VideoComponent } from "@hermes/aphrodite/video";
import { CTA, CTAToken, Media } from "@hermes/api-model-editorial";
import { Context } from "@hermes/app-core";

import {
    getMediaName,
    resolveCTA,
    setMediaImageAltFromCTA,
    showFigCaption,
} from "./media-cta.helpers";

const RETOUCHED_IMAGE = $localize`:@@hermes.image_retouched:Retouched`;

@Component({
    selector: "h-media-cta",
    templateUrl: "./media-cta.component.html",
    styleUrls: ["./media-cta.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaCtaComponent implements OnDestroy {
    @Input()
    public mediaOptions?: MediaQueryOption[];

    @Input()
    public preloadPoster = false;

    @Input()
    public showSoundControlButton = false;

    @Input()
    public isLightMode = false;

    // Used to pass to video component if we need to display a black gradient a the bottom of the video
    // (to display white text above the video)
    @Input()
    public displayGradient: boolean = false;

    @Input()
    public videoRatio?: `${number}-${number}`;

    @Output() public mediaRedirectionEvent = new EventEmitter<CTA>();

    @ViewChild(VideoComponent)
    public videoComponent: VideoComponent | undefined;

    public isKeyboardNavigation$ = this.layoutFacade.keyboardNavigation$;

    public subscription: Subscription = new Subscription();

    public isInServerMode: boolean = this.context.isInServerMode();

    public cta?: CTA;
    public mediaName!: string;
    public showFigCaption!: boolean;
    public videoUrl?: string;
    public mobileVideoUrl?: string;
    public ariaLabel?: string;
    public linkAriaLabel?: string;

    private prMedia!: Media;
    private prTitle?: string;

    private prIgnoreCaptions!: boolean;

    constructor(private context: Context, private layoutFacade: LayoutFacade) {}

    public get media(): Media | undefined {
        return this.prMedia;
    }

    @Input({ required: true })
    public set media(media: Media) {
        this.mediaName = getMediaName(media);
        this.showFigCaption = showFigCaption(media, this.prIgnoreCaptions);
        this.videoUrl = media.videoUrl;
        this.mobileVideoUrl = media.mobileVideoUrl;
        if (media.token) {
            this.cta = resolveCTA(media.token);
        }
        this.prMedia = setMediaImageAltFromCTA(media, this.cta);
        this.ariaLabel = this.displayAriaLabel();
    }

    @Input()
    public set ignoreCaptions(ignoreCaptions: boolean) {
        this.prIgnoreCaptions = ignoreCaptions;
        this.showFigCaption = showFigCaption(this.prMedia, ignoreCaptions);
    }

    @Input()
    public set token(token: CTAToken | undefined) {
        this.cta = resolveCTA(token);
        if (this.prMedia) {
            this.prMedia = setMediaImageAltFromCTA(this.prMedia, this.cta);
        }
    }

    @Input() public set pauseEvent$(pause$: Observable<void>) {
        this.setPauseObservable(pause$);
    }

    public get title(): string | undefined {
        return this.prTitle;
    }

    @Input() public set title(mediaTitle: string | undefined) {
        this.prTitle = mediaTitle;
        if (mediaTitle) {
            this.linkAriaLabel = $localize`:@@hermes.category_push.aria_label:Voir la catégorie ${mediaTitle}:INTERPOLATION:`;
        }
    }

    public setPauseObservable(pause$: Observable<void>) {
        this.subscription.add(
            pause$.subscribe(() => {
                this.pauseVideo();
            }),
        );
    }

    public mediaRedirection(value: CTA) {
        this.mediaRedirectionEvent.emit(value);
    }

    public pauseVideo() {
        if (this.videoComponent) {
            this.videoComponent.autoPauseVideo();
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public displayAriaLabel(): string | undefined {
        const { imageRetouched, caption, credits } = this.prMedia;
        const retouchedLabel = imageRetouched ? `${RETOUCHED_IMAGE} ` : "";

        if (caption || credits) {
            return `${retouchedLabel}${caption ?? ""}${
                credits ? ` ${credits}` : ""
            }`;
        }

        return retouchedLabel || this.title || undefined;
    }
}
