import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, of } from "rxjs";

import { CountrySelectorData } from "@hermes/api-model-country";
import { Settings } from "@hermes/app-core";
import { SKIP_404_INTERCEPTOR } from "@hermes/utils/constants";

@Injectable()
export class FetchCountriesService {
    constructor(private http: HttpClient, private settings: Settings) {}

    /**
     * The country-selector will be saved in the localstorage with a defined lifetime
     */
    public fetchCountries(): Observable<CountrySelectorData> {
        return this.http
            .get<CountrySelectorData>(
                `${this.settings.apiUrl}/country-selector`,
                {
                    context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
                },
            )
            .pipe(
                catchError(() => {
                    const countries: CountrySelectorData = {
                        areas: [],
                    };
                    return of(countries);
                }),
            );
    }
}
